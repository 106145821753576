import * as React from "react"
import { Helmet } from "react-helmet"
import '../styles/privacy.css'
import Icons from "../components/icons.js";
import Footer from "../components/footer.js";

import HomeSVG from "../images/2.0/home.svg";

// markup
const PrivacyPage = ({}) => {
  const [footerVisible, setFooterVisible] = React.useState(false);
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy - CULT&RAIN&trade;</title>
        <link rel="canonical" href="https://www.cultandrain.com/privacy" />
        <meta name="description" content="The first luxury fashion brand born from the crypto universe merging NFTs with exclusive, physical redeemables. Join our Web3 revolution." />
      </Helmet>
      <div className="privacy-page">
        <div className="wrapper">
          <div>
            <a href="/" className="homebtn"><img src={HomeSVG} /></a>
            <Icons footerVisible={footerVisible} mintVisible={true} />
          </div>
          <div className="privacy-body">
            <h1><u>PRIVACY POLICY</u></h1>
            <p>This privacy policy (the “Privacy Policy”) describes how your personal information is collected, used, and shared when you visit cultandrain.com (the “Website”) or use any services offered by Cult and Rain, Inc. (“Cult & Rain”, “we”, “our”, “us”).</p>
            <p>By accessing or using the Website, you consent to the information collection and data handling practices outlined in this Privacy Policy.</p>
            <p>Cult & Rain works with third-party applications that collect data, such as Discord or Collab.Land for NFT holder verifications or giveaways. We also use Google Analytics to help us understand who our Website visitors are and how they use the Website. You can read more about how Google uses your personal information here: <a href="https://www.google.com/intl/en/policies/privacy/" target="_blank">https://www.google.com/intl/en/policies/privacy/</a>. You can also opt-out of Google Analytics here: <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a>.</p>
            <h2>1.	The Data We Collect</h2>
            <p><strong>Location data:</strong> We collect precise or approximate location data from a user’s device if enabled by the user to do so (including GPS and WiFi data).</p>
            <p><strong>Demographic data:</strong> We may collect demographic data about users. We may also receive demographic data about users from third parties.</p>
            <p><strong>Transaction information:</strong> We collect transaction information related to the use of the Website, including the type of Web3 wallet connected, order details, Ethereum address, and date and time the purchase was made. You understand that the Ethereum network is a public blockchain and all of your transaction history initiated through the Website will be made public.</p>
            <p><strong>Usage data:</strong> We collect data about how users interact with our Website. This includes data such as access dates and times, features or pages viewed, app crashes and other system activity, type of browser and third-party sites or services used before interacting with our services. In some cases, we collect this data through cookies, pixels, tags, and similar tracking technologies that create and maintain unique identifiers.</p>
            <p><strong>Device data:</strong> We may collect data about the devices used to access our Website, including the hardware models, device IP address, operating systems and versions, software, preferred languages, unique device identifiers, device motion data, and mobile network data.</p>
            <h2>2.	How We Use the Data Collected</h2>
            <ul style={{listStyleType:'lower-alpha'}}>
              <li>To enhance the safety and security of our users and services:<br /><br />Perform internal operations necessary to provide our services, including to troubleshoot software bugs and operational problems; to conduct data analysis, testing, and research; and to monitor and analyze usage and activity trends.</li>
              <li>For research and development:<br /><br />We may use the data we collect for testing, research, analysis, product development and machine learning to improve the user experience. This helps us to improve and enhance the safety and security of our services, improve our ability to prevent the use of our services for illegal or improper purposes, develop new features and products and facilitate insurance and finance solutions in connection with our services.</li>
            </ul>
            <h2>3.	Data Retention</h2>
            <p>Cult & Rain retains transaction and other personal data for our records, unless the user requests deletion.</p>
            <ul style={{listStyleType:'lower-alpha'}}>
              <li>California Privacy Rights<br /><br />Under the California Consumer Protection Act of 2018 (“CCPA”), a California consumer has the following special rights:<br /><br />
                <ul style={{listStyleType:'disc'}}>
                  <li>right to request that the business disclose what personal information it collects, uses, discloses, and sells;</li>
                  <li>right to request the deletion of their personal information collected by the business; and</li>
                  <li>the right, at any time, to direct a business that sells personal information about the consumer to third parties not to sell the consumer’s personal information (“right to opt-out”).</li>
                </ul>
                <br /><br />California residents may request copies of the data collected about them, that their personal information collected by Cult & Rain be deleted or to exercise their right of opt out, California residents may make a request at <a href="mailto:hi@cultandrain.com">hi@cultandrain.com</a>.<br /><br />
              </li>
              <li>European Privacy Rights<br /><br />For residents of the European Economic Area (EEA), you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us at <a href="mailto:hi@cultandrain.com">hi@cultandrain.com</a>.<br /><br />Additionally, if you are a EEA resident, we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Website), or otherwise to pursue our legitimate business interests. Please note that your information will be transferred outside of Europe, including to the United States.</li>
            </ul>
            <h2>4.	Minors</h2>
            <p>Cult & Rain and our services are not directed toward children, and we don’t knowingly collect personal information from children under the age of 13. If we find out that a child under 13 has given us personal information, we will take steps to delete that information. If you believe that a child under the age of 13 has given us personal information, please contact us.</p>
            <h2>5.	Changes</h2>
            <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons, and will post any changes on the Website as soon as they go into effect.</p>
            <h2>6.	Definitions</h2>
            <p><strong>“Personal Information”</strong> is information that can be used to identify or contact you, such as your contact details, including your name, e-mail address and any other information that you provide in the course of requesting access and using the Website, including any data about your use of the services, which may be collected automatically.</p>
            <p><strong>“Order Details”</strong> is information provided by a user when making or attempting a purchase on the Website, that includes: Web3 wallet, Ethereum address, name, shoe size, e-mail address, and mailing address.</p>
          </div>
          <Footer setFooterVisible={setFooterVisible} />
        </div>
      </div>
    </main>
  )
}

export default PrivacyPage
